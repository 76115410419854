<template>
  <div class="d-flex align-items-center justify-content-center p-1">
    <b-img
      src="@/assets/images/logo/logo.png"
      alt="logo"
    />
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
/* eslint no-underscore-dangle: 0 */

export default {
  components: {
    BImg,
  },
}
</script>
