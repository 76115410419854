<template>
  <section>
    <b-row>
      <b-col
        md="12"
        class="mt- mb-1"
      >
        <dashboard-welcome />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
      >
        <b-card>
          <h3>Liste des devices</h3>
          <div
            v-for="device, indexDevice in $store.state.device.devicesFromUser"
            :key="indexDevice"
          >
            <b-link
              class="font-weight-bold"
              :to="{ name: 'devices-view', params: { id: device.id} }"
            >{{ device.deviceType.name }} - {{ device.name }}</b-link>
          </div>
        </b-card>
      </b-col>
      <b-col
        md="6"
      >
        <b-card>
          <h3>Liste des Utilisateurs</h3>
          <div
            v-for="user, indexUser in $store.state.user.usersList"
            :key="indexUser"
          >
            <b-link
              class="font-weight-bold"
              :to="{ name: 'users-view', params: { id: user.id} }"
            >{{ user.username }} - {{ user.enterprise }}</b-link>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BLink,
} from 'bootstrap-vue'
import DashboardWelcome from './DashboardWelcome.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    DashboardWelcome,
  },
}
</script>
