<template>
  <section id="dashboard">
    <div
      v-if="$store.state.auth.role === 1"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-1 text-center"
        >
          <dashboard-welcome />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="6"
          md="4"
        >
          <b-card
            no-body
          >
            <div>
              <b-card-header class="pb-0">
                <h4 class="mb-0">
                  Gmax > 250
                </h4>
              </b-card-header>
              <div
                v-if="gmaxData && gmaxData.length > 0"
              >
                <vue-apex-charts
                  type="radialBar"
                  height="260"
                  :options="goalOverviewRadialBar"
                  :series="gmaxData"
                />
              </div>
              <div
                v-else
                class="text-center mt-5 mb-5"
                style="height: 150px;"
              >
                <b-spinner
                  v-if="isLoadingGmax"
                  class="mt-5 mb-5"
                />
                <div
                  v-else
                >No data</div>
              </div>
              <b-row class="text-center mx-0">
                <b-col
                  cols="6"
                  class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    <small>Nombre d'impacts</small>
                  </b-card-text>
                  <h3
                    class="font-weight-bolder mb-0"
                  >
                    {{ gmaxTotal }}
                  </h3>
                </b-col>

                <b-col
                  cols="6"
                  class="border-top d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    <small>Nombre de sites</small>
                  </b-card-text>
                  <h3
                    class="font-weight-bolder mb-0"
                  >
                    {{ siteNumber }}
                  </h3>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col
          sm="6"
          md="4"
        >
          <b-card
            no-body
          >
            <div>
              <b-card-header class="pb-0">
                <b-link
                  :to="{ name: 'locations' }"
                >
                  <h4 class="mb-0">
                    Locations HIC
                  </h4>
                </b-link>
              </b-card-header>
              <div>
                <div class="rentalsduration">
                  {{ rentalDays }}
                  <div style="font-size: 0.5em;">
                    jours
                  </div>
                </div>
                <vue-apex-charts
                  height="240"
                  :options="chartOptionsLoc"
                  :series="locPourcent"
                />
              </div>
              <b-row class="text-center mx-0">
                <b-col
                  cols="4"
                  class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    <small class="text-warning">A venir</small>
                  </b-card-text>
                  <h3
                    class="font-weight-bolder mb-0 text-warning"
                  >
                    {{ locTodo }}
                  </h3>
                </b-col>
                <b-col
                  cols="4"
                  class="border-top border-right d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    <small class="text-info">En cours</small>
                  </b-card-text>
                  <h3
                    class="font-weight-bolder mb-0 text-info"
                  >
                    {{ locOngoing }}
                  </h3>
                </b-col>
                <b-col
                  cols="4"
                  class="border-top d-flex align-items-between flex-column py-1"
                >
                  <b-card-text class="text-muted mb-0">
                    <small class="text-success">Terminées</small>
                  </b-card-text>
                  <h3
                    class="font-weight-bolder mb-0 text-success"
                  >
                    {{ locDone }}
                  </h3>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="4"
          class="mb-3"
        >
          <lugnium-list
            :lugnium-list.sync="lugniumList"
            :lugnium-filter.sync="lugniumFilter"
          />
        </b-col>
      </b-row>
      <b-row
        v-if="$store.state.devicetype.devicetypesList && $store.state.devicetype.devicetypesList.length > 0"
      >
        <b-col
          sm="5"
          lg="8"
          class="mb-2"
        >
          <b-card class="d-flex flex-row h-100">
            <b-row>
              <b-col
                lg="4"
                class="pl-2 pr-2"
              >
                <div>
                  <b-card-title>Têtes HIC</b-card-title>
                  <b-row>
                    <b-col
                      v-for="devicetype, indexDevicetype in $store.state.devicetype.devicetypesList"
                      :key="indexDevicetype"
                      :style="!devicetype.name.includes('H')"
                      cols="12"
                    >
                      <div
                        v-if="devicetype && devicetype.name.includes('H')"
                        class="d-flex align-items-center mb-1"
                      >
                        <b-avatar
                          variant="light-success"
                          size="35"
                        >
                          <feather-icon
                            size="20"
                            icon="CpuIcon"
                          />
                        </b-avatar>
                        <h4 class=" ml-1 mb-0 font-weight-bolder">
                          {{ $store.state.device.devicesList.filter(device => device.is_active && device.deviceType_id === devicetype.id).length }}
                        </h4>
                        <span class="ml-1">{{ devicetype.name }}</span>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col
                lg="4"
                class="pl-2 pr-2"
              >
                <div>
                  <b-card-title>Valises</b-card-title>
                  <b-row>
                    <b-col
                      v-for="devicetype, indexDevicetype in $store.state.devicetype.devicetypesList"
                      :key="indexDevicetype"
                      :style="!devicetype.name.includes('alise') || devicetype.id === 1 ? 'display: none;' : null"
                      cols="12"
                    >
                      <div
                        v-if="devicetype && devicetype.id !== 1 && devicetype.name.includes('alise')"
                        class="d-flex align-items-center mb-1"
                      >
                        <b-avatar
                          variant="light-info"
                          size="35"
                        >
                          <feather-icon
                            size="20"
                            icon="BriefcaseIcon"
                          />
                        </b-avatar>
                        <h4 class=" ml-1 mb-0 font-weight-bolder">
                          {{ $store.state.device.devicesList.filter(device => device.is_active && device.deviceType_id === devicetype.id).length }}
                        </h4>
                        <span class="ml-1">{{ devicetype.name }}</span>
                      </div>
                    </b-col>
                    <div
                      v-if="$store.state.user.usersList"
                      class="d-flex align-items-center mt-1 p-1"
                    >
                      <b-avatar
                        variant="light-danger"
                        size="35"
                      >
                        <feather-icon
                          size="20"
                          icon="UserIcon"
                        />
                      </b-avatar>
                      <h4 class=" ml-1 mb-0 font-weight-bolder">
                        {{ $store.state.user.usersList.length }}
                      </h4>
                      <span class="ml-1">Utilisateurs</span>
                    </div>
                  </b-row>
                </div>
              </b-col>
              <b-col
                lg="4"
                class="pl-2 pr-2"
              >
                <div>
                  <b-card-title>Autres</b-card-title>
                  <b-row>
                    <b-col
                      v-for="devicetype, indexDevicetype in $store.state.devicetype.devicetypesList"
                      :key="indexDevicetype"
                      :style="indexDevicetype === ($store.state.devicetype.devicetypesList.length - 1) || devicetype.name.includes('alise') || devicetype.name.includes('H') ? 'display: none;' : null"
                      cols="12"
                    >
                      <div
                        v-if="devicetype && devicetype.id !== 1 && !devicetype.name.includes('alise') || !devicetype.name.includes('H')"
                        class="d-flex align-items-center mb-1"
                      >
                        <b-avatar
                          variant="light-warning"
                          size="35"
                        >
                          <feather-icon
                            size="20"
                            icon="CodesandboxIcon"
                          />
                        </b-avatar>
                        <h4 class=" ml-1 mb-0 font-weight-bolder">
                          {{ $store.state.device.devicesList.filter(device => device.is_active && device.deviceType_id === devicetype.id).length }}
                        </h4>
                        <span class="ml-1">{{ devicetype.name }}</span>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          sm="7"
          lg="4"
        >
          <b-card
            no-body
          >
            <b-card-header class="pb-0">
              <b-link
                :to="{ name: 'sav' }"
              >
                <h4 class="mb-0">
                  SAV
                </h4>
              </b-link>
            </b-card-header>
            <div
              v-if="savPourcent && savPourcent.length === 4"
            >
              <vue-apex-charts
                height="240"
                :options="chartOptions"
                :series="savPourcent"
              />
            </div>
            <div
              v-else
              class="text-center"
            >
              <b-spinner
                v-if="isLoadingSav"
                class="mt-5 mb-5"
              />
            </div>
            <b-row class="text-center mx-0">
              <b-col
                cols="3"
                class="border-top border-right d-flex align-items-between flex-column pt-1 pb-1 pl-0 pr-0"
              >
                <b-card-text class="text-muted mb-0">
                  <small class="text-warning">A faire</small>
                </b-card-text>
                <h4
                  class="font-weight-bolder mb-0 text-warning"
                >
                  {{ savTodo }}
                </h4>
              </b-col>
              <b-col
                cols="3"
                class="border-top border-right d-flex align-items-center flex-column pt-1 pb-1 pl-0 pr-0"
              >
                <b-card-text class="text-muted mb-0 ">
                  <small class="text-info">En cours</small>
                </b-card-text>
                <h4
                  class="font-weight-bolder mb-0 text-info"
                >
                  {{ savOngoing }}
                </h4>
              </b-col>
              <b-col
                cols="3"
                class="border-top border-right d-flex align-items-between flex-column pt-1 pb-1 pl-0 pr-0"
              >
                <b-card-text class="text-muted mb-0">
                  <small class="text-danger">Retard</small>
                </b-card-text>
                <h4
                  class="font-weight-bolder mb-0 text-danger"
                >
                  {{ savLate }}
                </h4>
              </b-col>
              <b-col
                cols="3"
                class="border-top d-flex align-items-between flex-column pt-1 pb-1 pl-0 pr-0"
              >
                <b-card-text class="text-muted mb-0">
                  <small class="text-success">Terminé</small>
                </b-card-text>
                <h4
                  class="font-weight-bolder mb-0 text-success"
                >
                  {{ savDone }}
                </h4>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="4"
        >
          <metrology-list :devices-list-met="devicesListMet" />
        </b-col>
        <b-col
          lg="4"
        >
          <metrology-late-list :devices-list-late-met="devicesListLateMet" />
        </b-col>
        <b-col
          lg="4"
        >
          <metrology-date :devices-list-metrology-date="eventMetrology" />
        </b-col>
      </b-row>
    </div>
    <div
      v-if="$store.state.auth.role === 2"
    >
      <dashboard-client />
    </div>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BSpinner,
  BCardHeader, BCardTitle, BCardText, BLink,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import VueApexCharts from 'vue-apexcharts'
import DashboardWelcome from './dashboard/DashboardWelcome.vue'
import MetrologyList from './dashboard/MetrologyList.vue'
import MetrologyLateList from './dashboard/MetrologyLateList.vue'
import MetrologyDate from './dashboard/MetrologyDate.vue'
import LugniumList from './dashboard/LugniumList.vue'
import DashboardClient from './dashboard/DashboardClient.vue'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BAvatar,
    DashboardWelcome,
    MetrologyList,
    MetrologyLateList,
    MetrologyDate,
    LugniumList,
    VueApexCharts,
    BCardTitle,
    DashboardClient,
    BCardText,
    BSpinner,
    BLink,
  },
  data() {
    return {
      eventsList: [],
      devicesList: [],
      scheduledDeviceId: [],
      lugniumFilter: 'ongoing',
      gmaxTotal: null,
      gmax300: null,
      gmaxData: [],
      siteNumber: null,
      rentalsDuration: 0,
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '70%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '1.6rem',
                fontWeight: '400',
                formatter(val) {
                  return `${val}‰`
                },
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.info],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      chartOptions: {
        chart: {
          type: 'donut',
          dropShadow: {
            enabled: true,
            blur: 8,
            left: 0,
            top: 0,
            opacity: 0.2,
          },
        },
        labels: ['En cours', 'A faire', 'Retard', 'Terminé'],
        dataLabels: {
          enabled: false,
          style: {
            fontSize: '2rem',
            fontWeight: '400',
            fontFamily: 'Helvetica, Arial, sans-serif',
            colors: ['#333'],
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          position: 'bottom',
          floating: false,
          labels: {
            colors: $themeColors,
            useSeriesColors: false,
          },
        },
        stroke: {
          width: 0.1,
          colors: 'rgb(200, 200, 200)',
        },
        colors: [$themeColors.info, $themeColors.warning, $themeColors.danger, $themeColors.success],
        plotOptions: {
          pie: {
            donut: {
              size: '92%',
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: 'Terminé',
                  formatter(w) {
                    return `${w.globals.seriesTotals[w.globals.seriesTotals.length - 1]}`
                  },
                },
              },
            },
          },
        },
      },
      chartOptionsLoc: {
        chart: {
          type: 'donut',
          dropShadow: {
            enabled: true,
            blur: 8,
            left: 0,
            top: 0,
            opacity: 0.2,
          },
        },
        labels: ['En cours', 'A venir', 'Terminé'],
        dataLabels: {
          enabled: false,
          style: {
            fontSize: '2rem',
            fontWeight: '400',
            fontFamily: 'Helvetica, Arial, sans-serif',
            colors: ['#333'],
          },
          dropShadow: {
            enabled: false,
          },
        },
        legend: {
          show: false,
          position: 'bottom',
          floating: false,
          labels: {
            colors: $themeColors,
            useSeriesColors: false,
          },
        },
        stroke: {
          width: 0.1,
          colors: 'rgb(200, 200, 200)',
        },
        colors: [$themeColors.info, $themeColors.warning, $themeColors.success],
        plotOptions: {
          pie: {
            donut: {
              size: '92%',
              labels: {
                show: false,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: false,
                  offsetY: 15,
                  label: 'Jours',
                  // formatter(w) {
                  //   return `${w.globals.seriesTotals[w.globals.seriesTotals.length - 1]}`
                  // },
                },
              },
            },
          },
        },
      },
      // loader
      isLoadingGmax: false,
      isLoadingSav: false,
      // state
      today: new Date(),
      // SAV
      savTotal: null,
      savPourcent: null,
      savOngoing: null,
      savTodo: null,
      savLate: null,
      savDone: null,
      // METROLOGY
      devicesListMet: null,
      eventMetrology: null,
      devicesListLateMet: null,
    }
  },
  computed: {
    // loc card
    locTotal() {
      return this.$store.state.rental.rentalsList
    },
    locPourcent() {
      return [this.locOngoing, this.locTodo, this.locDone]
    },
    locOngoing() {
      return this.locTotal.filter(loc => (Date.parse(loc.startDate) <= Date.parse(this.today.toString())) && (Date.parse(loc.endDate) >= Date.parse(this.today.toString()))).length
    },
    locTodo() {
      return this.locTotal.filter(loc => Date.parse(loc.startDate) > Date.parse(this.today.toString())).length
    },
    locDone() {
      return this.locTotal.filter(loc => Date.parse(loc.endDate) < Date.parse(this.today.toString())).length
    },
    rentalDays() {
      let data = 0
      this.locTotal.forEach(loc => {
        const rentalDuration = Date.parse(loc.endDate) - Date.parse(loc.startDate)
        data += rentalDuration
      })
      return data / (1000 * 3600 * 24)
    },
    lugniumList() {
      if (this.lugniumFilter === 'ongoing') {
        return this.eventsList
          .filter(event => event.title === 'Lugnium')
          .filter(lugnium => {
            const today = new Date()
            return (Date.parse(lugnium.endDate) > Date.parse(today.toString()) + 7884000000)
          })
          .sort((a, b) => Date.parse(a.endDate) - Date.parse(b.endDate))
      }
      if (this.lugniumFilter === 'toCome') {
        const toCome = this.eventsList
          .filter(event => event.title === 'Lugnium' && !event.boolData)
          .filter(lugnium => {
            const today = new Date()
            return (Date.parse(lugnium.endDate) < (Date.parse(today.toString()) + 7884000000))
          })
          .sort((a, b) => Date.parse(a.endDate) - Date.parse(b.endDate))
        this.$store.commit('app/HYDRATE_LUGNIUMLATE', toCome)
        return toCome
      } return false
      // return this.eventsList
      //   .filter(event => event.title === 'Lugnium')
      //   .filter(lugnium => {
      //     const today = new Date()
      //     return (Date.parse(lugnium.endDate) > Date.parse(today.toString()))
      //   })
      //   .sort((a, b) => Date.parse(a.endDate) - Date.parse(b.endDate))
    },
  },
  mounted() {
    this.isLoadingGmax = true
    this.isLoadingSav = true
    if (this.$store.state.event.eventsList.length > 0) {
      this.eventsList = this.$store.state.event.eventsList
      this.hydrateSavData(this.eventsList)
      this.$store.dispatch('device/findAllDevices')
        .then(async devices => {
          this.devicesList = devices
          await this.hydrateEventMetrology(this.eventsList, devices)
          await this.hydrateMetrologyList(devices)
          await this.hydrateMetrologyListLate(devices)
        })
    } else {
      this.$store.dispatch('event/findAllEvents')
        .then(events => {
          this.eventsList = events
          this.hydrateSavData(events)
          this.$store.dispatch('device/findAllDevices')
            .then(async devices => {
              this.devicesList = devices
              await this.hydrateEventMetrology(events, devices)
              await this.hydrateMetrologyList(devices)
              await this.hydrateMetrologyListLate(devices)
            })
        })
        .catch(err => {
          console.log('error findAllEvents', err)
          this.isLoadingSav = false
        })
    }
    this.$store.dispatch('data/findAllGmax')
      .then(gmax => {
        this.gmaxTotal = gmax.count
        this.gmax300 = gmax.data.length
        this.gmaxData.push(((gmax.data.length / gmax.count) * 1000).toFixed(4))
        this.isLoadingGmax = false
        this.$store.dispatch('data/findSiteNumber')
          .then(site => {
            this.siteNumber = site
          })
      })
      .catch(err => {
        console.log('error findAllGmax', err)
        this.isLoadingGmax = false
      })
  },
  methods: {
    hydrateSavData(eventsList) {
      this.savTotal = eventsList.filter(event => event.title === 'SAV')
      this.savOngoing = this.savTotal.filter(event => event.state === 'En cours').length
      this.savTodo = this.savTotal.filter(event => event.state === 'A faire').length
      this.savLate = this.savTotal.filter(event => event.state === 'Retard').length
      this.savDone = this.savTotal.filter(event => event.state === 'Terminé').length
      this.savPourcent = [this.savOngoing, this.savTodo, this.savLate, this.savDone]
      this.$store.commit('app/HYDRATE_SAVTODO', this.savTotal.filter(event => event.state === 'A faire'))
      this.$store.commit('app/HYDRATE_SAVLATE', this.savTotal.filter(event => event.state === 'Retard'))
      this.isLoadingSav = false
    },
    hydrateEventMetrology(eventsList, devicesList) {
      const data = eventsList
        .filter(event => event.title === 'Étalonnage' && event.state !== 'Terminé')
      const devicesListMetrologyDate = devicesList
        .filter(device => data.some(e => e.deviceId === device.id))
      data
        .map(event => {
          if (event.deviceId) {
            this.scheduledDeviceId.push(event.deviceId)
          }
          if (devicesListMetrologyDate && devicesListMetrologyDate.length > 0) {
            devicesListMetrologyDate.forEach(device => {
              if (device.id === event.deviceId) {
                /* eslint-disable no-param-reassign */
                event.device = device
              }
              return false
            })
          }
          return event
        })
      this.eventMetrology = data
        .filter(event => {
          const today = new Date()
          return (Date.parse(event.device.lastMetrology) < (Date.parse(today.toString()) - 7884000000))
        })
        .map(event => {
          if (event.device.events && event.device.events.some(ee => ee.title === 'Lugnium' && (Date.parse(ee.endDate) > Date.now()))) {
            event.lugnium = true
          }
          return event
        })
    },
    hydrateMetrologyList(devicesList) {
      this.devicesListMet = devicesList
        .filter(device => {
          const today = new Date()
          return (Date.parse(device.nextMetrology) < (Date.parse(today.toString()) + 7884000000) && Date.parse(device.nextMetrology) > Date.parse(today.toString())) && device.is_active
        })
        .sort((a, b) => Date.parse(a.nextMetrology) - Date.parse(b.nextMetrology))
        .filter(device => {
          if (!this.scheduledDeviceId.includes(device.id)) {
            return device
          } return null
        })
        .map(device => {
          if (device.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now()))) {
            /* eslint-disable no-param-reassign */
            device.lugnium = true
          }
          return device
        })
    },
    hydrateMetrologyListLate(devicesList) {
      const data = devicesList
        .filter(device => {
          const today = new Date()
          return (Date.parse(device.nextMetrology) < Date.parse(today.toString())) && device.is_active
        })
        .sort((a, b) => Date.parse(a.nextMetrology) - Date.parse(b.nextMetrology))
        .filter(device => {
          if (!this.scheduledDeviceId.includes(device.id)) {
            return device
          } return null
        })
        .map(device => {
          if (device.events.some(event => event.title === 'Lugnium' && (Date.parse(event.endDate) > Date.now()))) {
            /* eslint-disable no-param-reassign */
            device.lugnium = true
          }
          return device
        })
      this.$store.commit('app/HYDRATE_METROLOGYLATE', data)
      this.devicesListLateMet = data
    },
  },
}
</script>

<style>
.rentalsduration {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  font-size: 2rem;
}
</style>
