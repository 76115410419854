<template>
  <b-card
    v-if="devicesListMet"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="truncate mb-2">
        <h2 class="mb-25 font-weight-bolder">
          {{ devicesListMet.length }}
        </h2>
        <span>Étalonnages à venir</span>
      </div>
      <b-avatar
        variant="light-warning"
        size="45"
      >
        <feather-icon
          size="21"
          icon="ActivityIcon"
        />
      </b-avatar>
    </div>
    <div
      class="d-flex justify-content-center"
    >
      <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="flat-primary"
        @click="deploy"
      >
        <feather-icon
          :icon="deploylistMet ? 'ChevronDownIcon' : 'ChevronUpIcon'"
          size="20"
        />
      </b-button>
    </div>
    <div
      :style="deploylistMet ? 'max-height: 0px' : ''"
      class="card-deploy mt-1"
    >
      <b-list-group
        v-for="device, indexDevice in devicesListMet"
        :key="indexDevice"
      >
        <b-list-group-item>
          <b-link
            class="d-flex justify-content-between align-items-center text-decoration-none"
            :to="{ name: 'devices-view', params: { id: device.id } }"
          >
            <span>{{ device.name }} - {{ device.enterprise }}</span>
            <b-avatar
              v-if="device.lugnium"
              variant="light-info"
              size="32"
            >
              <feather-icon
                icon="ShieldIcon"
                size="20"
              />
            </b-avatar>
            <b-badge
              variant="light-warning"
              pill
              class="badge-round"
            >
              {{ device.nextMetrology | displayDate }}
            </b-badge>
          </b-link>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge,
  BAvatar, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BLink,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    devicesListMet: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      deploylistMet: true,
    }
  },
  methods: {
    deploy() {
      this.deploylistMet = !this.deploylistMet
    },
  },
}
</script>

<style scoped>
.card-deploy {
  overflow: auto;
}
</style>
