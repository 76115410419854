<template>
  <b-card
    class="card-congratulations h-100"
  >
    <b-img
      :src="require('@/assets/images/logo/decore-right.png')"
      class="img-lugnium-dash"
    />
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div
          class="truncate mb-1"
          style="z-index: 3;"
        >
          <h2
            v-if="lugniumList"
            class="ml-2 font-weight-bolder"
          >
            {{ lugniumList.length }}
          </h2>
          <div
            v-else
            class="text-center"
          >
            <b-spinner />
          </div>
          <h4>Lugnium <small>(
            {{ lugniumLabel }} )</small>
          </h4>
        </div>
      </div>
      <div
        class="d-flex justify-content-around"
      >
        <!-- <b-button
          v-b-tooltip.hover
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          title="Tout"
          variant="info"
          class="p-1"
          @click="filterLugnium('all')"
        >
          <feather-icon
            icon="ShieldIcon"
            size="20"
          />
        </b-button> -->
        <b-button
          v-b-tooltip.hover
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          title="En cours"
          variant="success"
          class="p-1"
          @click="filterLugnium('ongoing')"
        >
          <feather-icon
            icon="CheckIcon"
            size="20"
          />
        </b-button>
        <b-button
          v-b-tooltip.hover
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          title="A Relancer"
          variant="danger"
          class="p-1"
          @click="filterLugnium('toCome')"
        >
          <feather-icon
            icon="XIcon"
            size="20"
          />
        </b-button>
      </div>
      <div
        class="d-flex justify-content-center m-1"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-dark"
          @click="deploy"
        >
          <feather-icon
            :icon="deployLugniumList ? 'ChevronDownIcon' : 'ChevronUpIcon'"
            size="20"
          />
        </b-button>
      </div>
    </div>
    <div
      :style="deployLugniumList ? 'max-height: 0px' : ''"
      class="card-deploy"
    >
      <b-list-group
        v-for="lugnium, indexLugnium in lugniumList"
        :key="indexLugnium"
      >
        <b-list-group-item>
          <b-link
            class="d-flex justify-content-between align-items-center text-decoration-none"
            :to="{ name: 'devices-view', params: { id: lugnium.device.id } }"
          >
            <span>{{ lugnium.device.name }} - {{ lugnium.device.enterprise }}</span>
            <b-badge
              variant="light-success"
              pill
              class="badge-round"
            >
              {{ lugnium.endDate | displayDate }}
            </b-badge>
          </b-link>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge,
  BLink, BButton, BImg, VBTooltip, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BLink,
    BButton,
    BImg,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    lugniumList: {
      type: Array,
      default: null,
    },
    lugniumFilter: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      deployLugniumList: true,
      lugniumLabel: 'En cours',
    }
  },
  methods: {
    deploy() {
      this.deployLugniumList = !this.deployLugniumList
    },
    filterLugnium(data) {
      if (data === 'toCome') {
        this.lugniumLabel = 'A Relancer'
      } else {
        this.lugniumLabel = 'En cours'
      }
      this.$emit('update:lugniumFilter', data)
    },
  },
}
</script>

<style scoped>
.card-deploy {
  overflow: auto;
}
.img-lugnium-dash {
  width: 200px;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
